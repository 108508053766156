//JavaScript Document

jQuery( document ).ready(function($) {
    
	$(document).foundation();
	
	//$('#preloader').fadeOut();
	
	//SLIDER
	$('.slider').on('moved.zf.slider', function(){
		
		if($('#sliderOutput1').val() > 95) {
			$('.slider-handle').css('background-color','#8bba34');
			$('#contact_form .button1').removeAttr("disabled");
			
		} else {
			$('.slider-handle').css('background-color','#656766');
			$('#contact_form .button1').attr("disabled", "true");
		}
	});
	
	$('.hamburger').on('click', function(){
		
		$('.hamburger').toggleClass('is-active');
		$('.mobile-menu').toggle();
		$('html').toggleClass('locked');

	});
		$(window).scroll(function() {
		 if ($(this).scrollTop() > 250)
		 {
		  $('.sticky-header').addClass("active");
		 }
		 else
		 {
		  $('.sticky-header').removeClass("active");
		 }
		});
		//CONTACT FORM
	$('#contact_form')
	// to prevent form from submitting upon successful validation
	  .on("submit", function(ev) {
		ev.preventDefault();
		
		console.log("Submit for form id "+ev.target.id+" intercepted");
	 })	
	.on("forminvalid.zf.abide", function(ev,frm) {
		 console.log("Form id "+ev.target.id+" is invalid");
	  })
	.on("formvalid.zf.abide", function(ev,frm) {
		
			  	console.log("form is valid");
				$('#contact_form .button2').attr('disabled', 'true');
				$('#contact_form .loading').css('display','inline-block');
				var that = $('#contact_form'),
				url = that.attr("action"),
				method = that.attr("method"),
				data = {};
		
				that.find("[name]").each( function() {
					var that = $(this),
						name = that.attr("name"),
						value = that.val();

					data[name] = value;
				});
				$.ajax({
					url: url,
					type: method,
					data: data,
					success: function(response) {
						console.log('success');
						$('#contact_form').hide(); 
						$('.form .successMessage').show(); 
						
					},
					error: function(response){
						$('#contact_form .button1').attr('disabled', 'false');
                        $('#contact_form .loading').hide();
						
					}
					
				});
	});
	
	//ACCORDION MOBILE MENU
	$('.is-accordion-submenu-parent a').click(function(){
        $(this).unbind('click');
	});

	//SCROLLREVEAL
	window.sr = ScrollReveal();

	sr.reveal('.rvl', { 
		duration: 500,
		distance:'0px',
		scale:1,
		delay:500
		});
	sr.reveal('.long-reveal', { 
		duration: 500,
		distance:'0px',
		scale:1,
		delay:2000
		});

	sr.reveal('.str', { 
		duration: 500,
		origin: 'bottom',
		scale: 1
	}, 100);
	
	
	
});
